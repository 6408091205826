import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import hawkeye from "../images/hawkeye-logo.png"

const RightOfWay = () => (
  <Layout>
    <SEO title="Right-of-Way" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Right-of-Way</h1>
        <h2>Services include:</h2>
        <div className="row my-4">
          <div className="col-md-4">
            <ul>
              <li>Condemnation Support</li>
              <li>Construction Monitoring and Damage Settlement</li>
              <li>Construction Support</li>
              <li>Development of a comprehensive Right-of-Way Plan</li>
              <li>Document Control & Quality Control</li>
              <li>Document Management</li>
              <li>Document Preparation</li>
              <li>Due Diligence</li>
              <li>Eminent Domain Support</li>
              <li>Experienced, Educated & Highly Qualified Agents</li>
              <li>Feasibility Studies</li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li>Fee/Mineral Acquisition</li>
              <li>
                File Review/Data Entry using Artificial Intelligence & Natural
                Language Processing
              </li>
              <li>Trusted Irth Solutions Partner</li>
              <li>GIS/Mapping</li>
              <li>Trusted Irth Solutions Land & ROW Partner</li>
              <li>Land Administration Support/Document Recording</li>
              <li>Lease Records/Asset Management</li>
              <li>Non-Environmental Permitting</li>
              <li>Oil and Gas Lease Acquisition/Amendment</li>
              <li>Operations Support and Maintenance</li>
              <li>Project Management and Oversight</li>
            </ul>
          </div>
          <div className="col-md-4">
            <ul>
              <li>Public Meeting Attendance & Support</li>
              <li>Relocation Assistance</li>
              <li>Right-of-Way Acquisition/Amendment</li>
              <li>Right-of-Way Easement Negotiations</li>
              <li>Route and Site Selection</li>
              <li>Trusted Irth Solutions™ Projects Partner</li>
              <li>Settlement of Surface Damages</li>
              <li>Surface Site Acquisition</li>
              <li>Survey Permissions & Coordination</li>
              <li>Survey Support</li>
              <li>Tracking & Reporting Milestone Dates</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <p>
              In order to achieve success on your project, you need a partner
              that understands the intricacies involved in right-of-way
              acquisition. You need a partner that can execute your right-of-way
              processes and procedures, while still being able to solve any
              spontaneous challenges by using all available resources & drawing
              on experience. You need a partner that does not lose focus of
              their client’s objectives & goals. A partner that works hand in
              hand with your in-house land department to deliver exceptional
              results every time. Choose Gray Hawk Land Solutions as your
              partner and see the difference that experience & principal
              involvement makes.{" "}
            </p>
            <p>
              At Gray Hawk Land Solutions, we deliver on our promise to make
              your project a success. We start working with our clients on their
              projects during pre-planning and then continue through acquisition
              & construction until the end when it is time to review post
              project analysis and reporting. We strive to deliver best in class
              services for all your land management needs. Our goal is to create
              long lasting and mutually beneficial working relationships with
              all our clients by providing quality service at an exceptional
              value.{" "}
            </p>
          </div>
          <div className="col-lg-4">
            <img src={hawkeye} className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RightOfWay
